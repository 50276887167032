<template>
  <ConfirmDialog
    :value="isVisible"
    positiveButtonText="Ok"
    negativeButtonText=""
    title="Brak dostępu"
    text="Nie masz dostępu do dodatku medycznego."
    :positiveAction="handleOk"
  >
  </ConfirmDialog>
</template>

<script>
import { mapGetters } from "vuex";
import BooksyService from "@/services/booksy.service";

export default {
  props: {
    value: {
      type: Boolean|undefined,
    },
  },
  data() {
    return {};
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
  },
  computed: {
    ...mapGetters("ui", ["enabledMedicalAddon"]),
    isVisible() {
      // If this.value is not undefined then 
      // it means the popup is manually controlled
      if (this.value !== undefined) {
        return this.value;
      }

      return !this.enabledMedicalAddon;
    },
  },
  methods: {
    handleOk() {
      // Call browser back:
      BooksyService.back();
    },
  },
};
</script>
